.center_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 10px; */
    /* margin-top: -10px; */
}

.text_container{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px 10px 20px 10px; */
    /* backdrop-filter: blur(10px); */
    /* background-color: rgba(0, 0, 0, 0.6); */
}

.text{
    text-align: justify;
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 16px;
}