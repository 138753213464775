/* NotFound.css */

.not-found {
    background: linear-gradient(to bottom, #440b0b 40%,rgba(23, 170, 186, 0.728));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .not-found p {
    font-size: 1.5rem;
  }
  