.modal_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-content: center;
    height: 100dvh;
    width: 100%;
    z-index: 99;
    visibility: hidden;
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  .modal_container.modal_show {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
  }
  

.modal_sub_container{
    padding: 20px;
    display: flex;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    background-color: rgb(255, 255, 255);
    width: 350px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}

.close_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #121212c0;
    cursor: pointer;
}

.modal_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.main_heading p{
    text-align: justify;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #121212;
    font-weight: 400;
}

.paragraph p{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}

.btn_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.btn{
    /* background: linear-gradient(45deg,rgb(148, 148, 148) 0%,rgb(99, 99, 99)); */
    background: rgb(236, 47, 4);
    outline: none;
    border: none;
    border-radius: 2px;
    color: white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
    max-width: 150px;
    min-width: 150px;
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    text-align: center;
    transition: all 0.2s;
}

.btn_under{
    /* background: linear-gradient(45deg,rgb(148, 148, 148) 0%,rgb(99, 99, 99)); */
    /* background: #686868c6; */
    /* background: #686868c6; */
     background: #696969;
    outline: none;
    border: none;
    border-radius: 2px;
    /* color: #121212; */
    color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
    max-width: 150px;
    min-width: 150px;
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    text-align: center;
    transition: all 0.2s;
}

.btn:hover{
    background: rgb(185, 34, 0) !important;
}

.btn_under:hover{
    background: #454545;
}